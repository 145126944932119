@use "@angular/material" as mat;

$sm-theme: mat.define-light-theme(
  (
    density: -2
  )
);

.aha-blue {
  --mdc-typography-body1-font-size: 14px; // default to 16px
  // --mdc-typography-body1-line-height: 24px; // default to 24px
  // --mdc-typography-body1-letter-spacing: normal;

  .mat-mdc-form-field {
    --mat-mdc-form-field-floating-label-scale: .75;
  }

  .mat-form-field-hide-placeholder {
    --mdc-typography-body1-line-height: 22px; // default to 24px
  }

  @include mat.form-field-density($sm-theme);

  .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    font-size: 14px;
  }

  mat-form-field.mat-form-field-invalid .mat-mdc-form-field-subscript-wrapper {
    // Add spacing when mat-error is showing
    min-height: 32px;
    margin-bottom: .4rem;
  }

  mat-form-field:not(.mat-form-field-invalid) {
    margin-bottom: 1rem;
  }

  mat-form-field:not(.mat-form-field-invalid) .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-form-field-error-wrapper {
    padding: 0 0.5rem;
  }

  .mat-mdc-radio-button .mdc-radio {
    padding: 0.2rem;
  }
}

// https://stackoverflow.com/questions/46130916/angular-material-2-fix-for-multiline-error-messages
// mat-form-field.ng-invalid.ng-touched {
//   animation: expand;
//   animation-duration: 0.3s;
//   margin-bottom: 20px;
// }
// @keyframes expand {
//   from {
//     margin-bottom: 0;
//   }
//   to {
//     margin-bottom: 20px;
//   }
// }

// Workaround for https://github.com/angular/material2/issues/4580.
// mat-form-field .mat-form-field {
//   &-underline {
//     position: relative;
//     bottom: auto !important;
//   }
//   &-subscript-wrapper {
//     position: static;
//   }
// }

// mat-form-field {
//   margin-bottom: 10px;
// }
