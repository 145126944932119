.aha-blue {
  .mdc-dialog__title,
  [mat-dialog-title] {
    margin: 0.5rem 1.4rem;
    padding: 0.6rem 0 0.2rem;
    border-bottom: 1px solid #707070;

    h1,
    h2,
    h3 {
      color: map-get($blue, 900);
      font-weight: bold;
    }

    &.title {
      @apply tw-flex tw-justify-between tw-items-center;
    }
  }

  .mdc-dialog__title::before {
    display: none;
  }

  .mat-mdc-dialog-title h1 {
    margin-bottom: 0;
  }

  .mdc-dialog__title + .mdc-dialog__content,
  .mat-mdc-dialog-actions,
  [mat-dialog-content],
  [mat-dialog-actions] {
    padding: 1rem 1.4rem;
    overflow-x: hidden;
  }

  .mat-mdc-dialog-container {
    --mdc-dialog-supporting-text-color: #000;
    --mdc-dialog-supporting-text-size: .9rem;
  }
}
