@import "../../../../assets/styles/scss/colors";

.aha-blue {
  .mat-mdc-standard-chip {
    --mdc-chip-label-text-color: $white;
    --mdc-chip-elevated-container-color: $aha-blue;
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background-color: $aha-blue;
  }
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
    color: $white;
  }

  .mat-mdc-chip:not(.mat-mdc-basic-chip) {
    font-size: 0.75rem;
    padding: 0.1rem 0.1rem;
  }
}
