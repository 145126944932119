.mat-mdc-card {
  margin: 0.5rem;
  overflow: hidden;

  &.rounded-circle {
    border-radius: 200px !important;
  }
}
.mat-mdc-card.default {
  padding: 0;
}
.mat-mdc-card.default .mat-mdc-card-title {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
  font-weight: 400;
}
.mat-mdc-card.default > :first-child {
  border-radius: 2px 2px 0 0;
}
.mat-mdc-card.default .mat-mdc-card-subtitle:first-child {
  padding-top: 1rem;
}
.mat-mdc-card.default .mat-mdc-card-subtitle {
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
}
.mat-mdc-card.default .mat-mdc-card-content {
  padding: 1rem;
  margin-bottom: 0;
  position: relative;
}
.mat-mdc-card.default .mat-mdc-card-actions,
.mat-mdc-card.default .mat-mdc-card-actions:last-child {
  padding: 0.5rem;
  margin: 0;
}
.mat-mdc-card.default > :last-child {
  border-radius: 0 0 2px 2px;
}

.mat-mdc-card .mat-mdc-card-title .mat-divider,
.mat-divider.full-width {
  margin-left: -24px;
  margin-right: -24px;
}

.mat-mdc-card.p-0 .mat-mdc-card-title .card-title-text {
  padding: 1rem 1.5rem;
}
.mat-mdc-card.p-0 .mat-mdc-card-title .card-title-text .card-control {
  height: 24px;
  width: 24px;
  line-height: 24px;
}
.mat-mdc-card.p-0 .mat-mdc-card-title .card-title-text .mat-mdc-card-subtitle {
  margin: 0;
}
.mat-mdc-card.p-0 .mat-mdc-card-title .mat-divider {
  margin-left: 0;
  margin-right: 0;
  border-top-color: rgba(0, 0, 0, 0.04);
}
.mat-mdc-card.p-0 .mat-mdc-card-image {
  margin: 0 0 1rem !important;
}
.mat-mdc-card.p-0 .mat-mdc-card-content {
  padding: 0 1.5rem 1.5rem;
}

// Below are styles added after MDC migration

.mat-mdc-card.custom {
  padding: 0;

  .mat-mdc-card-header {
    display: block; // So tw-flex can work
    padding: 0.35rem 0.9rem;
    background: #f5f5f5;
    border-bottom: 1px solid #ddd;
  }

  .mat-mdc-card-content {
    padding: 0.9rem 0.9rem;
  }
}