.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-light {
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.border-top-light {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.border-right-light {
  border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.border-bottom-light {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.border-left-light {
  border-left: 1px solid rgba(255, 255, 255, 0.1) !important;
}
