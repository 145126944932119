.aha-blue {
  .mat-mdc-table.border {
    border: 1px solid #eee;
  }
  
  .mat-mdc-paginator-page-size {
    align-items: center;
  
    .mat-mdc-paginator-page-size-select {
      margin-bottom: 0;
    }
  }

  .mat-mdc-table {
    .mdc-data-table__header-row,
    .mdc-data-table__row {
      height: 48px;
    }
  }
}
