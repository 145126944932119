@use "@angular/material" as mat;

// https://v14.material.angular.io/guide/typography
$sm-typography: mat.define-typography-config(
  $button:
    mat.define-typography-level(
      $font-size: 14px,
    )
);

$sm-theme: mat.define-light-theme(
  (
    density: 0
  )
);

.aha-blue {
  --mdc-typography-button-text-transform: normal;
  --mdc-typography-button-letter-spacing: normal;
  // @include mat.button-density($sm-theme);
  // @include mat.button-typography($sm-theme);
  // @include mat.progress-spinner-density($sm-theme);
  // @include mat.progress-spinner-typography($sm-theme);

  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 44px;
    height: 44px;
    padding: 10px;
  }
}


/*-------------- Loading buttons --------------*/
.button-loading {
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-wrapper {
    display: flex;
    align-items: center;
  }
}
.btn-spinner {
  width: 1em;
  height: 1em;
  background: transparent;
  border-radius: 50%;
  margin: 0 16px 0 0;
  border: 2px solid transparent;
  animation: btn-glow 1s ease infinite;
}

@keyframes btn-glow {
  0% {
    box-shadow: 0 0 0 0.4em darken(#777676, 10%), 0 0 0 0.1em darken(#777676, 10%);
    transform: rotate(360deg);
  }
  50% {
    border-top-color: #777676;
  }
  100% {
    box-shadow: 0 0 0 0.4em darken(#777676, 10%), 0 0 0 3.6em transparent;
  }
}
