$lightGray: map-get($material-colors, grey);
$lightGrayColor: map-get($lightGray, 100);

$red: map-get($solid-colors, red);
$redColor: map-get($red, color);

$blue: map-get($material-colors, blue);

/*
// Library
*/


/*
// General
*/

.page {
  padding: 1rem 0.5rem 3rem;
}
.inline-block-middle {
  display: inline-block;
  vertical-align: middle;
}
.sidebar-opened {
  app-myprint app-info-carousel .left {
    left: 15rem;
  }
}
/*
TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.
*/
/*
TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.
*/
mat-card-title {
  font-weight: 800 !important;
}
.link {
  color: #0075c9;
  transition: all 0.1s linear;
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7);
}
.mat-mdc-card.custom:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.app-user .app-user-name {
  padding: 0 10px;
}
.mat-mdc-table {
  &.condensed {
    tr {
      height: 35px;
    }
  }
}
.overflow-x-auto {
  overflow-x: auto;
}
/*
TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.
*/
.aha-blue .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(0 171 105 / 0.5);
}
/*
TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.
*/
.aha-blue .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #00ab69;
}
.mat-mdc-tooltip,
.mat-mdc-tooltip.tooltip {
  font-size: 0.9rem !important;
}
@media only screen and (min-width: 1200px) {
  .tooltip-left {
    position: relative;
    right: 100%;
  }
}
.mat-mdc-progress-bar {
  z-index: 9;
}
table.hover tbody tr:hover {
  background: $lightGrayColor;
}
mat-icon {
  &.size-1 {
    height: auto;
    width: auto;
    font-size: 1rem;
  }
}

/*
TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.
*/
/*
TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.
*/
mat-chip {
  &.mat-primary {
    background-color: map-get($blue, 800) !important;
    color: $white !important;
  }
}

// Remove extra bottom padding when there are no errors
/*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
/*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
// mat-form-field.remove-pb[appearance="outline"]:not(.mat-form-field-invalid) .mat-form-field-wrapper,
// mat-form-field.remove-pb.mat-form-field-appearance-outline:not(.mat-form-field-invalid) .mat-form-field-wrapper {
//   padding-bottom: 0;
// }

@media only screen and (max-width: 599px) {
  .fixed-topbar .rightside-content-hold {
    // <app-header-side> gets smaller
    top: 56px;
  }
}

/*
// Employers
*/

.same-width-buttons {
  width: 13em;
}


/*
// Talent
*/

.masonry-cards {
  .masonry-cards__card {
    display: flex;

    > mat-card {
      width: 100%;
    }
  }
}

/*
// Internal
*/

/*
// Req Intake
*/

app-req-intake-form {
  // @media screen and (max-width: 1280px) {
  //   #cke_4_contents {
  //     height: 120px !important;
  //   }
  // }

  // @media screen and (min-width: 1281px) {
  //   #cke_4_contents {
  //     height: 500px !important;
  //   }
  // }

  @media screen and (min-width: 1281px) and (max-width: 1460px) {
    #ckContainer {
      width: 28vw !important;
    }
  }

  @media screen and (min-width: 1800px) {
    #ckContainer {
      width: 33vw !important;
    }
  }
}

app-req-intake-form {
  /*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
  /*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
  .selected.mat-form-field-appearance-outline .mat-form-field-outline {
      color: #0075c9 !important;
  }
  /*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
  /*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
  .selected.mat-form-field-appearance-outline .mat-form-field-outline .mat-form-field-outline-start,
  .selected.mat-form-field-appearance-outline .mat-form-field-outline .mat-form-field-outline-end,
  .selected.mat-form-field-appearance-outline .mat-form-field-outline .mat-form-field-outline-gap {
    border-width: 2.5px !important;
  }
  /*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
  /*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
  .required.mat-form-field-appearance-outline.ng-invalid .mat-form-field-outline {
    color: red !important;
  }
  /*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
  /*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
  .required.mat-form-field-appearance-outline:hover .mat-form-field-outline {
    color: black !important;
  }

  lib-ckeditor {
    border: 1px solid transparent;
  }
  
  lib-ckeditor.ng-invalid {
    border-color: $redColor;
  }
}

app-form-filters,
app-placement-detail-form,
app-field-skills {
  /*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
  /*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
  .mat-form-field-wrapper {
    padding-bottom: 0em;
  }
}

app-advanced-table {
  /*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
  .mat-mdc-form-field-wrapper {
    padding-bottom: 0em;
  }
}

app-dialog-req-intake-details {
  p {
    margin: 0 0 0 !important;
  }
}

app-table-new-hires {
  app-spinner-overlay-wrapper {
    .overlay {
      display: block !important;
    }
  }
}

app-candidate-package-form-email {
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    padding: 0px;
    height: 0px;
  }
}

.modal-download-package .mat-mdc-dialog-container {
  height: auto !important;
}

.mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
  opacity: 0.40 !important;
  transform: translateY(0px) !important;
}

app-internal-job-openings {
  app-table {
    th.mat-mdc-header-cell:first-of-type {
      max-width: 150px!important;
    }

    th.mat-mdc-header-cell {
      max-width: 100px!important;
    }
  }
}

app-direct-hire-information-table,
app-contractor-timesheet-information-table {
  app-table {
    .table-container {
      height: 100% !important;

      table {
        .mat-mdc-table {
          height: 100% !important;
          
          .condensed tr {
            height: 35px !important;
          }
        }
      }
    }
  }

  app-table.show-selected {
    tr.mat-mdc-row.selected {
      background-color: $lightGrayColor;
    }
    .mat-column-placementId,
    .mat-column-placementId-Filter {
      max-width: 98px !important;
    }
    .mat-column-invoiceNumber,
    .mat-column-invoiceNumber-Filter {
      max-width: 98px !important;
    }
    .mat-column-fee,
    .mat-column-fee-Filter {
      max-width: 68px !important;
    }
    .mat-column-flatFee,
    .mat-column-flatFee-Filter {
      max-width: 105px !important;
    }
    .mat-column-paidInFullDate,
    .mat-column-paidInFullDate-Filter {
      max-width: 68px !important;
    }
    .mat-column-dateBegin,
    .mat-column-dateBegin-Filter {
      max-width: 105px !important;
    }
  }
  .mat-column-select {
    width: 30px;
  }
  .mat-column-isRedFlagged {
    width: 30px;
  }
  .mat-column-isPayrollConfirmed {
    width: 30px;
  }
  .mat-column-isARConfirmed {
    width: 48px;
  }
  table > thead > tr:nth-child(2) {
    > th:nth-child(2) > mat-form-field,
    > th:nth-child(3) > mat-form-field,
    > th:nth-child(4) > mat-form-field {
      display: none;
    }
  }
}

app-pending-employee-changes {
  app-table {
    td.mat-mdc-cell:last-of-type {
        padding-right: 0px !important;
        padding-left: 8px !important;
    }
  }
}

app-internal-directory {
  table > thead > tr:nth-child(2) {
    > th:nth-child(9) > mat-form-field {
      display: none;
    }
  }
}