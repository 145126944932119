.home-section {
  padding: 80px 0;
}
.scrollable {
  position: relative;
  width: 100%;
  height: 100vh;
}
.section-intro {
  padding: 100px 0 30px;
  background: #2b303b url(/assets/images/home-bg-black.png) center center/cover no-repeat;
  color: #ffffff;
  h1 {
    font-size: 32px;
    margin: 2rem 0 1rem;
    font-weight: 500;
  }
  p {
    font-size: 16px;
    max-width: 450px;
    margin: 0 auto 1.4rem;
  }
  img {
    display: block;
    margin: 0 auto;
  }
}
