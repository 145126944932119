// Third party style files
@import 'ngx-toastr/toastr';

// Sidebar Scroll
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

@import "scss/variables";
@import "scss/mixins/mixins";
@import "scss/colors";
@import "scss/animations";
@import "scss/themes/themes";
@import "scss/utilities/utilities";
@import "scss/components/components";
@import "scss/palette";
@import "scss/page-layouts";
@import "scss/main/main";
@import "scss/modules/modules";
@import "scss/custom";